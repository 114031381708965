<template>

	<div style="display:flex;gap:50px;width: 800px;">
		<div style="flex: 1; border-right:1px solid #000;">
			<div style="width: 500px;margin: 20px 0;">
				<h3>视频通话组件是一款全局组件 在需要的地方调用this.videoCall()方法进行使用</h3>
				<h3>视频通话使用例子1 单人视频通话</h3>
				<p>我的uuid:{{user.uuid}}</p>
				<p>传入对方uuid即可:<input v-model="hisUUID" style="width: 100%"></p>
				<el-button @click="test1">拨打</el-button>
			</div>

			<hr>

			<div style="width: 500px;margin: 20px 0;">
				<h3>视频通话使用例子2 多人视频通话</h3>
				<p>我的uuid:{{user.uuid}}</p>
				<p>传入对方多人uuid即可:</p>
				<p><el-button @click="test2">拨打</el-button></p>


				<p>当前uuid:{{UUIDList.length}}个</p>
				<el-tag
					:key="uuid"
					v-for="uuid in UUIDList"
					closable
					:disable-transitions="false"
					@close="handleClose(uuid)">
					{{uuid}}
				</el-tag>
				<el-input
					class="input-new-tag"
					v-if="inputVisible"
					v-model="inputValue"
					ref="saveTagInput"
					size="small"
					@keyup.enter.native="handleInputConfirm"
					@blur="handleInputConfirm"
				>
				</el-input>
				<el-button v-else class="button-new-tag" size="small" @click="showInput">加一个</el-button>


			</div>
		</div>

	</div>

</template>

<script>

    export default {
        name: "test1",
        components:{

        },
        computed:{
            user() {
                return this.$store.state.user;
            },
			is_login() {
				return this.$store.state.is_login;
			},
        },
        data(){
            return{
				hisUUID:"1ef48-10323-4a87-bc41-11fd8", //对方uuid
				UUIDList:["1af19-14895-4512-9c3a-4abd0",
					"1a3a3-2f66f-413b-8935-4b845",
					"13496-19cd3-44e9-ab0d-95c96",
					"198ea-1ff66-4aab-8be6-9c542",
					"1ef48-10323-4a87-bc41-11fd8",
					"1c89f-1c336-4e1f-9ac6-c0f5f",
					"102b8-12bac-43fe-a648-e5e6b",
					"1e173-141a5-4a0e-ad07-1ffa6",
					"1e0b7-1509c-43fa-a3ff-63733",
					"1d977-18505-45ab-a319-00ec2",
					"11967-1a459-4d70-8ac4-86d9a",
					"17490-163f7-46dd-b56c-87618",
					"12c80-17666-478b-90af-5ccaa",
					"158a5-14a40-4f65-a7bb-d696f",
					"1f22a-2327c-435e-acfa-d945f",
					"146ae-1a5f3-47d0-94f4-1d5a8",
					"14b6a-23c02-456d-87d9-e7639",
					"10208-173aa-4d7e-9727-dacab",
					"148ae-1d981-4289-a6cf-32483",
					"1cbce-1b437-4586-a714-738a6",
					"1f9db-16f45-4066-863f-29dbc",
					"1101f-2b52a-402a-b938-ab86b",
					"12198-14b5c-4763-afbd-92374",
					"1c4ed-354cc-4984-b720-9965b",
					"14972-355ed-43ab-a1b9-74307",
					"1f741-3a35a-458e-a823-e5411",
					"1af1a-3a394-4f7d-8f6f-4863a",
					"16bf1-3e815-4c26-bd56-9137d",
					"12825-3ec81-4720-b74e-920e6",
					"1c209-1d836-4b06-bfca-bd54d",
					"19c25-24bb1-4393-a79b-9d227",
					"15b14-23df1-42e2-b38e-cdaea",
					"1cd52-20d92-4b2a-8a4a-f840b",
					"130fe-37a67-4473-a850-7b056",
					"171c5-31ee2-42e7-b3f1-aaeb6",
					"176e2-291b8-45ab-a526-135dd",
					"1cf19-24e8a-42b3-bf1b-33f02",
					"1cab4-3a27a-4eae-bfd5-3a3a7",
					"165b6-346c0-4123-af0b-a10d8",
					"1ccd7-336fc-472f-ad00-92a05",
					"17546-13a53-436e-9264-3b9be",
					"12275-10c7f-4261-a15c-594f0",
					"1e22a-12ccb-483f-bf8c-6da58",
					"155db-1ddb9-4b8c-9c06-d5571",
					"2b9fb-1d755-448a-8eba-040d9",
					"22c21-15727-4fd6-a59e-f26c3",
					"28cf1-153da-48f8-94ac-23942",
					"29091-149c0-47f5-88e7-7a144",
					"254b2-11941-4732-981a-ef9d6",
					"2cafe-1f6b1-44dc-8abf-79271"],


				inputVisible: false,
				inputValue: ''
            }
        },
        mounted() {

        },
        methods:{
			handleClose(tag) {
				this.UUIDList.splice(this.UUIDList.indexOf(tag), 1);
			},

			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.UUIDList.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			},
			test1(){
				this.videoCall(this.hisUUID);
			},
			test2(){
				var UUIDList = JSON.parse(JSON.stringify(this.UUIDList));
				this.videoCall(UUIDList);
			}
        }
    }
</script>

<style scoped>
</style>